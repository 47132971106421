import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import knob from "../../assets/images/knob.svg";
import flag from "../../assets/images/flag.svg";
import line from "../../assets/images/line.svg";
import oven from "../../assets/images/oven.svg";
import box from "../../assets/images/box.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Divider, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";

const step = ["", ""];

const slots = [
  "12-1",
  "1-2",
  "2-3",
  "3-4",
  "4-5",
  "5-6",
  "6-7",
  "7-8",
  "8-9",
  "9-10",
  "10-11",
  "11-12",
];

function Steps() {
  const [SlotsAM, setSlotsAM] = React.useState(() => []);
  const [SlotsPM, setSlotsPM] = React.useState(() => []);
  const [cookerType, setCookerType] = React.useState();

  const [unitCost, setUnitCost] = React.useState(0);
  const [runningCosts, setRunningCosts] = React.useState(0);

  const onCalculate = () => {
    setRunningCosts(unitCost * 2 * SlotsAM.length + SlotsPM.length);
  };

  const handleOptionChange = (changeEvent) => {
    setCookerType(changeEvent.target.value);
  };

  const handleSlotsAM = (event, newFormats) => {
    setSlotsAM(newFormats);
  };

  const handleSlotsPM = (event, newFormats) => {
    setSlotsPM(newFormats);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep == 1) {
      console.log("On Calculate");
      onCalculate();
    } else {
      console.log("On Next");
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-12">
          <Stepper activeStep={activeStep}>
            {step.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === step.length ? (
            <React.Fragment>
              <Typography
                sx={{ mt: 3, mb: 3, fontWeight: "bold", textAlign: "center" }}
                variant="h5"
              >
                All steps completed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleReset} size="large" variant="outlined">
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <div>
                  <Typography
                    sx={{
                      mt: 3,
                      mb: 3,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    variant="h5"
                  >
                    SELECT YOUR COOKER TYPE
                  </Typography>
                  <div className="row justify-content-center justify-content-md-start">
                    <div className="col-sm-7">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="cooker-selection">
                            <input
                              type="radio"
                              required
                              id="2oven"
                              name="oventype"
                              value={2}
                              checked={cookerType === "2"}
                              onChange={handleOptionChange}
                            ></input>
                            <label htmlFor="2oven" className="cursor-pointer">
                              <Typography variant="h6" className="mb-3">
                                2 OVEN
                              </Typography>
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div
                                  className="col-12 my-3"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <img
                                    src={line}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6 mb-3">
                                  <img
                                    src={flag}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6 mb-3">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="cooker-selection">
                            <input
                              type="radio"
                              required
                              id="3oven"
                              name="oventype"
                              value={3}
                              checked={cookerType === "3"}
                              onChange={handleOptionChange}
                            ></input>
                            <label htmlFor="3oven" className="cursor-pointer">
                              <Typography variant="h6" className="mb-3">
                                3 OVEN
                              </Typography>
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div
                                  className="col-12 my-3"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <img
                                    src={line}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6 mb-3">
                                  <img
                                    src={flag}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6 mb-3">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-6">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 mb-3">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="cooker-selection">
                            <input
                              type="radio"
                              required
                              id="4oven"
                              name="oventype"
                              value={4}
                              checked={cookerType === "4"}
                              onChange={handleOptionChange}
                            ></input>
                            <label htmlFor="4oven" className="cursor-pointer">
                              <Typography variant="h6" className="mb-3">
                                4 OVEN
                              </Typography>
                              <div className="row">
                                <div className="col-4 d-flex">
                                  <img
                                    src={box}
                                    alt=""
                                    className="img-fluid mt-auto"
                                  />
                                </div>
                                <div className="col-4">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div
                                  className="col-8 offset-4 my-3"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <img
                                    src={line}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4 mb-3">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4 mb-3">
                                  <img
                                    src={flag}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4 mb-3">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="col-4">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeStep === 1 ? (
                <div>
                  <div className="row">
                    <Typography
                      sx={{
                        mt: 3,
                        mb: 3,
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                      variant="h5"
                    >
                      Click time slots to calculate cost
                    </Typography>
                    <div className="col-lg-6 mb-3 d-flex flex-column">
                      <Typography className="me-3 fw-bold text-nowrap mb-2">
                        Slots AM
                      </Typography>
                      <ToggleButtonGroup
                        value={SlotsAM}
                        onChange={handleSlotsAM}
                      >
                        {slots.map((d, i) => (
                          <ToggleButton
                            key={i}
                            value={d}
                            aria-label="left aligned"
                          >
                            <Typography>{d}</Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </div>
                    <div className="col-lg-6 mb-3 d-flex flex-column">
                      <Typography className="me-3 fw-bold text-nowrap mb-2">
                        Slots PM
                      </Typography>
                      <ToggleButtonGroup
                        value={SlotsPM}
                        onChange={handleSlotsPM}
                      >
                        {slots.map((d, i) => (
                          <ToggleButton
                            key={i}
                            value={d}
                            aria-label="left aligned"
                          >
                            <Typography>{d}</Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </div>
                    <div className="col-12 mb-3">
                      <Divider sx={{ backgroundColor: "#ccc" }} />
                    </div>
                    {cookerType !== "4" ? (
                      <div className="col-md-7">
                        {cookerType === "2" ? (
                          <div className="col-md-9 col-lg-7 col-xl-6 option-selection">
                            <Typography
                              variant="h6"
                              className="mb-3 text-center"
                            >
                              2 OVEN
                            </Typography>
                            <div className="row">
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="2-oven-knob-left"
                                  name="2-oven-knob-left"
                                />
                                <label for="2-oven-knob-left">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="2-oven-knob-right"
                                  name="2-oven-knob-right"
                                />
                                <label for="2-oven-knob-right">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div
                                className="col-12 my-3"
                                style={{ lineHeight: "1px" }}
                              >
                                <img src={line} alt="" className="img-fluid" />
                              </div>
                              <div className="col-6 mb-3">
                                <img src={flag} alt="" className="img-fluid" />
                              </div>
                              <div className="col-6 mb-3 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="2-oven-middle-right"
                                  name="2-oven-middle-right"
                                />
                                <label for="2-oven-middle-right">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="2-oven-left-bottom"
                                  name="2-oven-left-bottom"
                                />
                                <label for="2-oven-left-bottom">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="2-oven-right-bottom"
                                  name="2-oven-right-bottom"
                                />
                                <label for="2-oven-right-bottom">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-9 col-lg-7 col-xl-6 option-selection">
                            <Typography
                              variant="h6"
                              className="mb-3 text-center"
                            >
                              3 OVEN
                            </Typography>
                            <div className="row">
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="3-oven-knob-left"
                                  name="3-oven-knob-left"
                                />
                                <label for="3-oven-knob-left">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="3-oven-knob-right"
                                  name="3-oven-knob-right"
                                />
                                <label for="3-oven-knob-right">
                                  <img
                                    src={knob}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div
                                className="col-12 my-3"
                                style={{ lineHeight: "1px" }}
                              >
                                <img src={line} alt="" className="img-fluid" />
                              </div>
                              <div className="col-6 mb-3">
                                <img src={flag} alt="" className="img-fluid" />
                              </div>
                              <div className="col-6 mb-3 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="3-oven-middle-right"
                                  name="3-oven-middle-right"
                                />
                                <label for="3-oven-middle-right">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="3-oven-left-bottom"
                                  name="3-oven-left-bottom"
                                />
                                <label for="3-oven-left-bottom">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                              <div className="col-6 cb-wrapper">
                                <input
                                  type="checkbox"
                                  id="3-oven-right-bottom"
                                  name="3-oven-right-bottom"
                                />
                                <label for="3-oven-right-bottom">
                                  <img
                                    src={oven}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <Typography
                          variant="h6"
                          mt={2}
                          color={"primary"}
                          fontWeight={"normal"}
                          className="lh-sm text-center text-md-start"
                        >
                          Select Ovens Hob to be running when calculating cost
                        </Typography>
                      </div>
                    ) : (
                      <div className="col-md-5">
                        <div className="option-selection">
                          <Typography variant="h6" className="mb-3 text-center">
                            4 OVEN
                          </Typography>
                          <div className="row">
                            <div className="col-4 d-flex">
                              <img
                                src={box}
                                alt=""
                                className="img-fluid mt-auto"
                              />
                            </div>
                            <div className="col-4 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-knob-top-center"
                                name="4-oven-knob-top-center"
                              />
                              <label for="4-oven-knob-top-center">
                                <img src={knob} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div className="col-4 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-knob-top-right"
                                name="4-oven-knob-top-right"
                              />
                              <label for="4-oven-knob-top-right">
                                <img src={knob} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div
                              className="col-8 offset-4 my-3"
                              style={{ lineHeight: "1px" }}
                            >
                              <img src={line} alt="" className="img-fluid" />
                            </div>
                            <div className="col-4 mb-3 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-middle-left"
                                name="4-oven-middle-left"
                              />
                              <label for="4-oven-middle-left">
                                <img src={oven} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div className="col-4 mb-3">
                              <img src={flag} alt="" className="img-fluid" />
                            </div>
                            <div className="col-4 mb-3 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-middle-right"
                                name="4-oven-middle-right"
                              />
                              <label for="4-oven-middle-right">
                                <img src={oven} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div className="col-4 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-left-bottom"
                                name="4-oven-left-bottom"
                              />
                              <label for="4-oven-left-bottom">
                                <img src={oven} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div className="col-4 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-middle-bottom"
                                name="4-oven-middle-bottom"
                              />
                              <label for="4-oven-middle-bottom">
                                <img src={oven} alt="" className="img-fluid" />
                              </label>
                            </div>
                            <div className="col-4 cb-wrapper">
                              <input
                                type="checkbox"
                                id="4-oven-right-bottom"
                                name="4-oven-right-bottom"
                              />
                              <label for="4-oven-right-bottom">
                                <img src={oven} alt="" className="img-fluid" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <Typography
                          variant="h6"
                          mt={2}
                          color={"primary"}
                          fontWeight={"normal"}
                          className="lh-sm text-center text-md-start"
                        >
                          Select Ovens Hob to be running when calculating cost
                        </Typography>
                      </div>
                    )}
                    <div
                      className={
                        cookerType === "4"
                          ? "col-md-6 mt-4 mt-md-0 offset-md-1 d-flex flex-column"
                          : "col-md-5 mt-4 mt-md-0 d-flex flex-column"
                      }
                    >
                      <Paper className="w-100 p-4 mb-4">
                        <div className="d-flex justify-content-center flex-column text-center">
                          <Typography variant="h5">
                            Unit Cost in Pence
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            value={unitCost}
                            onChange={(e) => setUnitCost(e.target.value)}
                            sx={{
                              my: 2,
                              width: "100px",
                              mx: "auto",
                              input: {
                                textAlign: "center",
                                fontSize: "24px",
                                padding: "10px",
                              },
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "normal" }}
                            color={"primary"}
                            className="lh-sm"
                          >
                            If your cost is different, please amend
                          </Typography>
                        </div>
                      </Paper>
                      <Paper className="w-100 p-4 unit-banner mt-auto">
                        <div className="d-flex justify-content-center flex-column text-center">
                          <Typography
                            variant="h6"
                            className="text-white mb-3 lh-sm fw-normal"
                          >
                            Running costs based on time and oven / hob selection
                          </Typography>
                          <Typography variant="h3" className="text-white">
                            £ {runningCosts}
                          </Typography>
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <Box sx={{ display: "flex", flexDirection: "row", py: 3 }}>
                <Button
                  color="inherit"
                  size="large"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="outlined"
                >
                  <i className="bi bi-arrow-left me-2"></i> Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button
                    color="inherit"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                    size="large"
                  >
                    Skip
                  </Button>
                )}

                <Button onClick={handleNext} variant="contained" size="large">
                  {activeStep === step.length - 1 ? "Calculate" : "Next"}{" "}
                  <i className="bi bi-arrow-right ms-2"></i>
                </Button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
export default Steps;
