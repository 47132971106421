import './App.css';
import Steps from './components/Steps/Steps';
function App() {

  return (
    <div className="App">
      <Steps></Steps>
    </div>
  );
}

export default App;
